import React, { useState, useEffect } from "react";
import {
  Tab,
  Tabs,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Paper,
  TextField,
  Grid,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const correctEmployeeUsername = "Employee";
const correctEmployeePassword = "Employee123!";

const rolePasswords = {
  "Web Developers": "DEVELOPER@123",
  "SEO Experts": "SEO@123",
  "Graphic Designers": "DESIGNER@123",
  "Content Writers": "WRITER@123",
  "Cold Callers": "CALLER@123",
  "Social Media": "SMM@123",
};

const EmployeePortal = ({ tasks, setTasks }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [rolePassword, setRolePassword] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [employeesByRole] = useState({
    "Web Developers": [
      "Basil Ahmad (Senior WEB DEVELOPER)",
      "Tajammal (WEB DEVELOPER)",
      "Fatima (WEB DEVELOPER)",
    ],
    "SEO Experts": ["Bilal (Senior SEO Expert)", "Umair (SEO)", "Ibrahim (SEO)"],
    "Graphic Designers": [
      "Usama (Senior Graphic Designer)",
      "Sajeel (Graphic Designer/ Video Editor)",
      "Usaf Malik (Graphic Designer)",
    ],
    "Content Writers": ["Sehrish (Content Writer)", "Khadija (Content Writer)"],
    "Cold Callers": ["Noman Malik (Calling Agent)"],
    "Social Media": ["Hamid (Google Ads Expert)", "Mubashir (SMM Manager)"],
  });

  useEffect(() => {
    const storedRolePasswords = localStorage.getItem("rolePasswords");
    if (!storedRolePasswords) {
      localStorage.setItem("rolePasswords", JSON.stringify(rolePasswords));
    }

    const storedUsername = localStorage.getItem("employeeUsername");
    const storedPassword = localStorage.getItem("employeePassword");
    if (storedUsername && storedPassword) {
      setUsername(storedUsername);
      setPassword(storedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleLogin = () => {
    if (username === correctEmployeeUsername && password === correctEmployeePassword) {
      if (rememberMe) {
        localStorage.setItem("employeeUsername", username);
        localStorage.setItem("employeePassword", password);
      } else {
        localStorage.removeItem("employeeUsername");
        localStorage.removeItem("employeePassword");
      }
      setLoggedIn(true);
      setLoginError("");
    } else {
      setLoginError("Invalid username or password");
    }
  };

  const handleTaskCompletion = (taskId) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) => (task.id === taskId ? { ...task, completed: true } : task))
    );
  };

  const handleTabChange = (event, newValue) => {
    const role = roleKeys[newValue];
    setSelectedRole(role);
    setOpenPasswordDialog(true);
  };

  const handlePasswordDialogClose = () => {
    setOpenPasswordDialog(false);
  };

  const handlePasswordDialogSubmit = () => {
    const storedRolePasswords = JSON.parse(localStorage.getItem("rolePasswords"));
    const correctPassword = storedRolePasswords[selectedRole];

    if (rolePassword === correctPassword) {
      setOpenPasswordDialog(false);
      setCurrentTab(roleKeys.indexOf(selectedRole));
    } else {
      setLoginError("Incorrect password!");
    }
  };

  const handleTaskOverdue = (taskId) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.id === taskId ? { ...task, completed: true, overdue: true } : task
      )
    );
  };

  const isDeadlineOver = (deadline) => {
    const today = new Date();
    const deadlineDate = new Date(deadline);
    return deadlineDate < today;
  };

  const roleKeys = Object.keys(employeesByRole);
  const currentRole = roleKeys[currentTab];
  const employees = employeesByRole[currentRole] || [];
  const tasksForCurrentRole = tasks.filter((task) => employees.includes(task.employee));

  if (!loggedIn) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100vh", backgroundColor: "#f0f0f0" }}
      >
        <Grid item xs={12} sm={8} md={6}>
          <Paper elevation={3} sx={{ padding: 4, borderRadius: "12px" }}>
            <Box textAlign="center">
              <img
                alt="Shyft Logo"
                src={require("../assets/shyft_logo.png")}
                style={{ height: 100 }}
              />
              <Typography variant="h5" sx={{ mt: 2, mb: 3 }}>
                Employee Login
              </Typography>
              {loginError && (
                <Box mb={2}>
                  <Typography variant="body2" color="error">
                    {loginError}
                  </Typography>
                </Box>
              )}
            </Box>
            <TextField
              label="Username"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: showPassword ? (
                  <VisibilityOff
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <Visibility
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: "pointer" }}
                  />
                ),
              }}
              sx={{ mb: 2 }}
            />
            <FormControlLabel
              control={
                <Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
              }
              label="Remember Me"
              sx={{ mb: 2 }}
            />
            <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
              Login
            </Button>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Employees Portal
      </Typography>
      <Tabs
        value={currentTab}
        onChange={(event, newValue) => handleTabChange(event, newValue)}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        sx={{ marginBottom: 2 }}
      >
        {roleKeys.map((role, index) => (
          <Tab key={index} label={role} />
        ))}
      </Tabs>

      <Paper
        sx={{
          padding: "20px",
          marginTop: "20px",
          borderRadius: "12px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <Typography variant="h5">{currentRole}</Typography>
        <Table sx={{ backgroundColor: "white" }}>
          <TableHead>
            <TableRow>
              <TableCell>Employee</TableCell>
              <TableCell>Task</TableCell>
              <TableCell>Project</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Deadline</TableCell>
              <TableCell>Completion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasksForCurrentRole.map((task) => (
              <TableRow
                key={task.id}
                sx={{
                  backgroundColor: task.completed
                    ? "lightgreen"
                    : isDeadlineOver(task.deadline)
                    ? "lightcoral"
                    : "white",
                  transition: "background-color 0.3s",
                }}
              >
                <TableCell>{task.employee}</TableCell>
                <TableCell>{task.task}</TableCell>
                <TableCell>{task.projectName}</TableCell>
                <TableCell>{task.time}</TableCell>
                <TableCell>{task.deadline}</TableCell>
                <TableCell>
                  {task.completed ? (
                    "Completed"
                  ) : isDeadlineOver(task.deadline) ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleTaskOverdue(task.id)}
                    >
                      Overdue
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleTaskCompletion(task.id)}
                      disabled={isDeadlineOver(task.deadline)}
                    >
                      Complete
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>

      <Dialog open={openPasswordDialog} onClose={handlePasswordDialogClose}>
        <DialogTitle>Enter Password for {selectedRole}</DialogTitle>
        <DialogContent>
          <TextField
            label="Password"
            type="password"
            fullWidth
            value={rolePassword}
            onChange={(e) => setRolePassword(e.target.value)}
            sx={{ mb: 2 }}
          />
          <FormControlLabel
            control={
              <Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
            }
            label="Remember Me"
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePasswordDialogClose}>Cancel</Button>
          <Button onClick={handlePasswordDialogSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EmployeePortal;
