import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import ManagerPortal from "./components/ManagerPortal";
import SubManagerPortal from "./components/SubManagerPortal"; // Import SubManagerPortal
import EmployeePortal from "./components/EmployeePortal";
import NavBar from "./components/Navbar";
import { MainScreen } from "./components/MainScreen";
import { SnackbarProvider } from "notistack";
import { loadTasks, saveTasks } from "./storage";

const App = () => {
  const [tasks, setTasks] = useState(loadTasks);
  useEffect(() => {
    saveTasks(tasks);
  }, [tasks]);

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: "top", horizontal: "right" }} // Default position
    >
      <Router>
        <NavBar />
        <Box style={{ padding: "20px" }}>
          <Routes>
            <Route path="/" element={<MainScreen tasks={tasks} />} />
            <Route path="/manager" element={<ManagerPortal tasks={tasks} setTasks={setTasks} />} />
            <Route
              path="/sub-manager"
              element={<SubManagerPortal tasks={tasks} setTasks={setTasks} />}
            />{" "}
            {/* Add SubManagerPortal Route */}
            <Route
              path="/employee"
              element={<EmployeePortal tasks={tasks} setTasks={setTasks} />}
            />
          </Routes>
        </Box>
      </Router>
    </SnackbarProvider>
  );
};

export default App;
