import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Avatar,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Link } from "react-router-dom";
import "../assets/shyft_logo.png";

export const MainScreen = ({ tasks }) => {
  const incompleteTasks = tasks.filter((task) => !task.completed);

  return (
    <Box
      sx={{
        textAlign: "center",
        padding: "50px",
        background: "linear-gradient(to right, #7F7FD5, #86A8E7, #91EAE4)",
        borderRadius: "20px",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Grid container spacing={2} direction="column" alignItems="center">
        <Grid item>
          <img
            alt="Shyft Logo"
            src={require("../assets/shyft_logo.png")}
            style={{ height: 80, width: 200 }}
          />
        </Grid>
        <Grid item>
          <Typography variant="h3" sx={{ color: "white", marginTop: 3 }}>
            Welcome to the Task Portal
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  bgcolor: "white",
                  color: "black",
                  "&:hover": {
                    bgcolor: "black",
                    color: "white",
                  },
                }}
                component={Link}
                to="/manager"
              >
                Manager Portal
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" sx={{ bgcolor: "black" }} component={Link} to="/employee">
                Employees Portal
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="h5" sx={{ color: "white", marginTop: 4 }}>
            Incomplete Tasks
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: "white" }}>Employee</TableCell>
                <TableCell sx={{ color: "white" }}>Task</TableCell>
                <TableCell sx={{ color: "white" }}>Project</TableCell>
                <TableCell sx={{ color: "white" }}>Time</TableCell>
                <TableCell sx={{ color: "white" }}>Deadline</TableCell>
                <TableCell sx={{ color: "white" }}>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {incompleteTasks.map((task, index) => (
                <TableRow
                  key={task.id}
                  sx={{ "&:nth-of-type(odd)": { backgroundColor: "rgba(255, 255, 255, 0.1)" } }}
                >
                  <TableCell
                    sx={{ color: "white", borderBottom: "1px solid rgba(255, 255, 255, 0.3)" }}
                  >
                    {task.employee}
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", borderBottom: "1px solid rgba(255, 255, 255, 0.3)" }}
                  >
                    {task.task}
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", borderBottom: "1px solid rgba(255, 255, 255, 0.3)" }}
                  >
                    {task.projectName}
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", borderBottom: "1px solid rgba(255, 255, 255, 0.3)" }}
                  >
                    {task.time}
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", borderBottom: "1px solid rgba(255, 255, 255, 0.3)" }}
                  >
                    {task.deadline}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "white",
                      borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                      textAlign: "left",
                    }}
                  >
                    {task.description}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Box>
  );
};
