import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Visibility, VisibilityOff, Edit, Delete } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import shyftLogo from "../assets/shyft_logo.png";
import emailjs from "emailjs-com";

const correctUsername = "Shyft";
const correctPassword = "Shyft123!";
const initialEmployees = [
  "Basil Ahmad (Senior WEB DEVELOPER)",
  "Tajammal (WEB DEVELOPER)",
  "Fatima (WEB DEVELOPER)",
  "Bilal (Senior SEO Expert)",
  "Umair (SEO)",
  "Ibrahim (SEO)",
  "Usama (Senior Graphic Designer)",
  "Sajeel (Graphic Designer/ Video Editor)",
  "Usaf Malik (Graphic Designer)",
  "Mevish (Graphic Designer)",
  "Sehrish (Content Writer)",
  "Khadija (Content Writer)",
  "Mubashir (SMM Manager)",
  "Hamid (Google Ads Expert)",
  "Noman Malik (Calling Agent)",
];

const employeeEmails = {
  "Basil (Senior WEB DEVELOPER)": "basil.shyftdigitally@gmail.com",
  "Tajammal (WEB DEVELOPER)": "Tajammal.shyftdigitally@gmail.com",
  "Fatima (WEB DEVELOPER)": "fatimarajpoot570@gmail.com",
  "Bilal (Senior SEO Expert)": "Bilal22@gmail.com",
  "Umair (SEO)": "Umair123@gmail.com",
  "Ibrahim (SEO)": "Ibrahim332@gmail.com",
  "Usama (Senior Graphic Designer)": "m.usamaaawan@gmail.com",
  "Sajeel (Graphic Designer/ Video Editor)": "sajeelasghar99@gmail.com",
  "Usaf (Graphic Designer)": "usafmalik7860@gmail.com",
  "Sehrish (Content Writer)": "zehrishakbar78@gmail.com",
  "Khadija (Content Writer)": "khadijimubashir018@gmail.com",
  "Noman (Cold Caller)": "Noman007@gmail.com",
};

const ManagerPortal = ({ tasks, setTasks }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [employee, setEmployee] = useState("");
  const [task, setTask] = useState("");
  const [description, setDescription] = useState("");
  const [deadline, setDeadline] = useState("");
  const [time, setTime] = useState("");
  const [projectName, setProjectName] = useState("");
  const [editTask, setEditTask] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    const storedPassword = localStorage.getItem("password");
    if (storedUsername === correctUsername && storedPassword === correctPassword) {
      setLoggedIn(true);
    }
  }, []);

  const EMAIL_SERVICE_ID = "service_mktcanc";
  const EMAIL_TEMPLATE_ID = "template_gqz68tb";
  const EMAILJS_USER_ID = "I0BG6ZYiuAjCoEgs3";

  const sendEmail = (employee, task, deadline, time, description) => {
    const toEmail = employeeEmails[employee];
    console.log("Sending email to:", toEmail); // Log email
    emailjs
      .send(
        EMAIL_SERVICE_ID,
        EMAIL_TEMPLATE_ID,
        {
          to_email: toEmail,
          employee_name: employee,
          task_name: task,
          description: description,
          deadline: deadline,
          time: time,
        },
        EMAILJS_USER_ID
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
        },
        (error) => {
          console.error("Failed to send email:", error);
        }
      );
  };

  const handleLogin = () => {
    if (username === correctUsername && password === correctPassword) {
      if (rememberMe) {
        localStorage.setItem("username", username);
        localStorage.setItem("password", password);
      }
      setLoggedIn(true);
      setLoginError("");
    } else {
      setLoginError("Invalid username or password");
    }
  };

  const handleAddTask = () => {
    if (employee && task && deadline && time && projectName) {
      const newTask = {
        id: uuidv4(),
        employee,
        task,
        description,
        deadline,
        time,
        projectName,
        completed: false,
      };
      setTasks((prevTasks) => [...prevTasks, newTask]);
      setEmployee("");
      setTask("");
      setDescription("");
      setDeadline("");
      setTime("");
      setProjectName("");
      enqueueSnackbar("Task successfully added!", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 1000,
      });

      sendEmail(employee, task, deadline, time, description);
    } else {
      enqueueSnackbar("Please fill in all fields, including deadline, time and project name.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 1000,
      });
    }
  };

  const handleDeleteTask = (taskId) => {
    setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
  };

  const handleEditTask = (task) => {
    setEditTask(task);
    setEditModalOpen(true);
  };

  const handleEditSubmit = () => {
    if (
      editTask.employee &&
      editTask.task &&
      editTask.deadline &&
      editTask.time &&
      editTask.projectName
    ) {
      setTasks((prevTasks) => prevTasks.map((task) => (task.id === editTask.id ? editTask : task)));
      setEditModalOpen(false);
      setEditTask(null);
    } else {
      enqueueSnackbar("Please fill in all fields for editing.", { variant: "error" });
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const isDeadlineOver = (deadline, completed) => {
    const today = new Date();
    const deadlineDate = new Date(deadline);
    return !completed && deadlineDate < today;
  };

  const renderTaskStatus = (task) => {
    const today = new Date();
    const deadlineDate = new Date(task.deadline);
    if (task.completed) {
      if (deadlineDate < today) {
        return "Completed But Overdue";
      } else {
        return "Completed";
      }
    } else {
      return deadlineDate < today ? "Overdue" : "Pending";
    }
  };

  if (!loggedIn) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
        <Card style={{ padding: 20, width: "50%" }}>
          <CardContent>
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                <img alt="Shyft Logo" src={shyftLogo} style={{ height: 100 }} />
              </Grid>
              <Grid item>
                <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                  Manager Login
                </Typography>
              </Grid>
              {loginError && (
                <Grid item>
                  <Alert severity="error">{loginError}</Alert>
                </Grid>
              )}
              <Grid item sx={{ width: "70%" }}>
                <TextField
                  label="Username"
                  value={username}
                  fullWidth
                  onChange={(e) => setUsername(e.target.value)}
                  sx={{ minWidth: 300 }}
                />
              </Grid>
              <Grid item sx={{ width: "70%" }}>
                <TextField
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  fullWidth
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ minWidth: 300 }}
                />
              </Grid>
              <Grid item sx={{ width: "70%" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                  }
                  label="Remember Me"
                />
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleLogin}>
                  Login
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Manager Portal
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Assign New Task</Typography>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel>Employee</InputLabel>
                <Select value={employee} onChange={(e) => setEmployee(e.target.value)}>
                  {initialEmployees.map((emp) => (
                    <MenuItem key={emp} value={emp}>
                      {emp}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                label="Task"
                value={task}
                onChange={(e) => setTask(e.target.value)}
                fullWidth
                sx={{ mt: 2 }}
              />

              <TextField
                label="Deadline"
                type="date"
                value={deadline}
                onChange={(e) => setDeadline(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ mt: 2 }}
              />
              <TextField
                label="Time"
                type="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ mt: 2 }}
              />
              <TextField
                label="Project Name"
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                fullWidth
                sx={{ mt: 2 }}
              />
              <TextField
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                multiline
                rows={4}
                sx={{ mt: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddTask}
                sx={{ mt: 2 }}
                fullWidth
              >
                Assign Task
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card>
            <CardContent>
              <Typography variant="h6">Tasks List</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Employee</TableCell>
                    <TableCell>Task</TableCell>
                    <TableCell>Project</TableCell>
                    <TableCell>Deadline</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tasks.map((task) => (
                    <TableRow
                      key={task.id}
                      sx={{
                        bgcolor:
                          task.completed && isDeadlineOver(task.deadline, task.completed)
                            ? "lightcoral"
                            : task.completed
                            ? "lightgreen"
                            : "inherit",
                        "&:hover": {
                          backgroundColor: "#f4f4f4",
                        },
                      }}
                    >
                      <TableCell>{task.employee}</TableCell>
                      <TableCell>{task.task}</TableCell>
                      <TableCell>{task.projectName}</TableCell>
                      <TableCell>{task.deadline}</TableCell>
                      <TableCell>{task.time}</TableCell>
                      <TableCell>{renderTaskStatus(task)}</TableCell>
                      <TableCell>
                        {!task.completed && (
                          <IconButton color="primary" onClick={() => handleEditTask(task)}>
                            <Edit />
                          </IconButton>
                        )}
                        <IconButton color="secondary" onClick={() => handleDeleteTask(task.id)}>
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {editTask && (
        <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)}>
          <DialogTitle>Edit Task</DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Employee</InputLabel>
              <Select
                value={editTask.employee}
                onChange={(e) => setEditTask({ ...editTask, employee: e.target.value })}
              >
                {initialEmployees.map((emp) => (
                  <MenuItem key={emp} value={emp}>
                    {emp}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Task"
              value={editTask.task}
              onChange={(e) => setEditTask({ ...editTask, task: e.target.value })}
              fullWidth
              sx={{ mt: 2 }}
            />

            <TextField
              label="Deadline"
              type="date"
              value={editTask.deadline}
              onChange={(e) => setEditTask({ ...editTask, deadline: e.target.value })}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ mt: 2 }}
            />
            <TextField
              label="Time"
              type="time"
              value={editTask.time}
              onChange={(e) => setEditTask({ ...editTask, time: e.target.value })}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ mt: 2 }}
            />
            <TextField
              label="Project Name"
              value={editTask.projectName}
              onChange={(e) => setEditTask({ ...editTask, projectName: e.target.value })}
              fullWidth
              sx={{ mt: 2 }}
            />
            <TextField
              label="Description"
              value={editTask.description}
              onChange={(e) => setEditTask({ ...editTask, description: e.target.value })}
              fullWidth
              multiline
              rows={4}
              sx={{ mt: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditModalOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleEditSubmit} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default ManagerPortal;
