import React from "react";
import { AppBar, Toolbar, IconButton, Typography, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

const NavBar = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton component={Link} to="/" edge="start" color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          Shyft Digitally Task Portal
        </Typography>
        <Button color="inherit" component={Link} to="/manager">
          Manager Portal
        </Button>
        <Button color="inherit" component={Link} to="/sub-manager">
          Sub-Manager Portal
        </Button>
        <Button color="inherit" component={Link} to="/employee">
          Employees Portal
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
